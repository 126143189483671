const colors = {
  customTeal: '#2F7A7C',
  maroon: '#A2084D',
  black: {
    500: '#1a202c' // black clore
  },
  teal: {
    100: '#E6F3F3',
    150: '#E9F7F9',
    200: '#00A1B7', // unofficial
    300: '#00879A', // unofficial
    400: '#007787', // unofficial
    500: '#389295',
    600: '#2F7A7C', // same as customTeal
    700: '#256163',
    800: '#00343C', // unofficial
    900: '#001B1F' // unofficial
  },
  neutral: {
    0: '#FFFFFF',
    50: '#F7F9F9', // light mode bg grey
    100: '#EFF3F3', // light mode divider
    150: '#F1F4F4', // light mode divider
    200: '#E6E8E8', // light mode borders
    300: '#D5D8D8',
    333: '#333333',
    400: '#AFB4B4', // quarternary light mode text colour
    500: '#899090', // tertiary light mode text colour
    600: '#636D6D', // secondary light mode text colour
    700: '#272B2B', // primary light mode text colour
    800: '#1E2121',
    900: '#151717' // dark mode bg
  },
  yellow: {
    300: '#F5B840',
    400: '#E1B033',
    500: '#E1B033'
  },
  secondary: {
    alert: '#DF5C64', // red.400
    music: '#6FB36D', // green.400
    live: '#BD3E6F', // pink.400
    sleep: '#594C94', // purple.500
    playlists: '#599ACF', // blue.400
    courses: '#D9A853', // yellow.400
    workshop: '#599ACF' // blue.400
  },
  workplaceBlue: {
    25: '#CFDBF2',
    100: '#F5F8FC',
    400: '#406FCB',
    500: '#345BA6'
  },
  blue: {
    400: '#599ACF'
  },
  purple: {
    400: '#6F5FB4',
    500: '#594C94'
  },
  pink: {
    400: '#BD3E6F'
  },
  text: {
    darkest: '#222222',
    darkGray: '#181818',
    medium: '#5a5a5a',
    medDarkGray: '#222222',
    lightGray: '#727272'
  },
  blackAlpha: {
    600: '#0000007a'
  },
  green: {
    400: '#6FB36D',
    500: '#62A15F',
    900: '#026A6D' // unofficial
  },
  cream: {
    200: '#F8F4EF',
    300: '#F3EDE6'
  },
  sky: {
    100: '#7feef1',
    400: '#1C95E0'
  },
  lemonYellow: {
    200: '#D9D9CC'
  },
  modal: {
    light: {
      300: '#F5F5F5',
      500: '#ECECEC'
    }
  },
  error: '#EC4641',
  retreat: {
    green: '#42AA8B'
  }
};

export default colors;
